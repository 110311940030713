import { useState, useEffect } from 'react';
import { firestore } from "../firebase";

const useFetchFlats = (flatId) => {
  const [flats, setFlats] = useState(null);
  const [flat, setFlat] = useState(null);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    if (flatId) {
      firestore.collection('flats')
        .doc(flatId).get()
        .then((doc) => {
          if (doc.exists) {
            setFlat({ ...doc.data(), id: doc.id });
            setLoading(false);
          } else {
            setError("Nepodarilo sa načítať detail bytu. Skúste obnoviť stránku alebo zvoliť iný byt.");
            setLoading(false);
          }
        })
        .catch(err => {
          setError("Nepodarilo sa načítať detail bytu. Skúste obnoviť stránku alebo zvoliť iný byt.");
          setLoading(false);
        });
    } else {
      firestore.collection('flats')
        .orderBy("floor", "asc")
        .orderBy("floorPosition", "asc")
        .onSnapshot((snap) => {
          let documents = [];
          snap.forEach(doc => {
            documents.push({ ...doc.data(), id: doc.id });
          });
          setFlats(documents);
          setLoading(false);
          (documents.length === 0) && setError("Nepodarilo sa načítať byty. Skúste obnoviť stránku alebo zvoliť iné podlažie.");
        });
    }
  }, [flatId]);

  return { flats, flat, loading, error };
}

export default useFetchFlats;
