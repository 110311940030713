import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import ContactDrawer from "./ContactDrawer";
import hamburger from "../assets/images/hamburger.svg";
import darkHamburger from "../assets/images/dark-hamburger.svg";
import PhoneHeader from "./PhoneHeader";
import useWindowDimensions from "../utils/windowDimension";

const Container = styled.header`
  position: sticky;
  height: 100px;
  @media only screen and (max-width: 600px) {
    height: 80px;
  }
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1100;
  background-color: ${props => props.changingTransparent ? `rgba(252,251,248,${props.scrollState * 2})` : `rgba(252,251,248,1)`};
  border-bottom : 0.35px solid ${props => ((props.scrollState * 2) < 1 && props.changingTransparent) ? `rgba(252,251,248,0.8)` : "#CDCBAC"};
  transition: all 400ms linear;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const Logo = styled.div`
  svg {
    cursor: pointer;
    > path {
      fill: ${props => ((props.scrollState) < 0.2 && props.changingTransparent) ? props.theme.colors.cream : props.theme.colors.darkGreen};
      transition: fill 300ms linear;
    }
    path:nth-child(-n+5) {
      @media only screen and (max-width: 900px) {
        display: none;
      }
    }
  };
`;

const Hamburger = styled.img`
  display: none;
  cursor: pointer;
  @media only screen and (max-width: 900px) {
    display: block;
  }
  :hover {
    opacity: 0.7;
  }
`;

const Nav = styled.nav`
  @media only screen and (min-width: 901px) {display: flex};
  display: none;
  height: 100%;
  align-items: center;
  color: ${props => ((props.scrollState) < 0.2 && props.changingTransparent) ? props.theme.colors.cream : props.theme.colors.darkGreen};
  transition: color 300ms linear;
`;

const Menu = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 40px;
  cursor: pointer;

  > span:hover, a:hover {
    opacity: 0.7;
  }
`;

const OfferButton = styled.button`
  display: block;
  @media only screen and (max-width: 900px) {display: none};
  position: fixed;
  bottom: 80px;
  right: 40px;
  width: 140px;
  background: #B1A585;
  color: ${props => props.theme.colors.cream};
  padding: 20px 26px;

  :hover {
    opacity: 0.8;
  }
`;

const ContactButton = styled.button`
  background: ${props => ((props.scrollState) < 0.2 && props.changingTransparent) ? "rgba(238, 237, 222, 0.2)" : props.theme.colors.cream};
  color: ${props => ((props.scrollState) < 0.2 && props.changingTransparent) ? props.theme.colors.cream : props.theme.colors.darkGreen};
  transition: all 300ms linear;

  :hover {
    opacity: 0.8;
  }
`;

const SignOutButton = styled.button`
  @media only screen and (max-width: 900px) {display: none};
  background: ${props => ((props.scrollState) < 0.2 && props.changingTransparent) ? "rgba(238, 237, 222, 0.2)" : props.theme.colors.cream};
  color: ${props => ((props.scrollState) < 0.2 && props.changingTransparent) ? props.theme.colors.cream : props.theme.colors.darkGreen};
  transition: all 300ms linear;

  :hover {
    opacity: 0.8;
  }
`;

const DropDown = styled.div`
  display: block;
  position: absolute;
  top: 80px;
  transition: all 0.4s;
  height: 0px;
  background-color: ${props => props.changingTransparent? `rgba(252,251,248,${(props.scrollState * 2) + 0.2})` : `rgba(252,251,248,1)`};
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;

  ${Menu}:hover & {
    height: auto;
    top: 65px;
  }

  > a {
    display: none;
    padding: 12px 16px;
    ${Menu}:hover & {
      display: block;
    }

    :hover {
      opacity: 0.7;
    }
  }
`;

const Header = ({ changingTransparent, user, handleSignOut }) => {
  const [scrollState, setScrollState] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  let history = useHistory();
  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    let listener = document.addEventListener("scroll", e => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled / (windowDimensions.height - 100) < 1) {
        setScrollState(scrolled/(windowDimensions.height - 100)); 
      } else {
        setScrollState(1);
      }
    })
    return () => {
      document.removeEventListener("scroll", listener)
    }
  }, [scrollState, changingTransparent, windowDimensions.height])

  return (
    <Container scrollState={scrollState} changingTransparent={changingTransparent}>
      <Content className={"container"}>
        <Logo scrollState={scrollState} changingTransparent={changingTransparent}>
          <Link to="/" aria-label="Domovska stranka">
            <svg width="150" height="37" viewBox="0 0 150 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M70.9081 37C67.7964 37 65.2237 36.322 63.19 34.9659C61.2299 33.6584 60.017 31.697 59.5515 29.0939C59.527 28.9244 59.6373 28.767 59.7965 28.7307L63.9006 27.7621C64.0721 27.7258 64.2436 27.8347 64.2804 28.0042C64.6601 29.8324 65.4442 31.1158 66.5958 31.8301C67.8209 32.5929 69.2542 32.9682 70.8958 32.9682C72.5007 32.9682 73.738 32.6656 74.6079 32.0723C75.4777 31.479 75.9065 30.6678 75.9065 29.6629C75.9065 28.6944 75.5022 27.98 74.6814 27.532C73.8606 27.0841 72.6722 26.7087 71.0919 26.4182L69.3767 26.1276C67.7351 25.837 66.2527 25.4133 64.9051 24.8684C63.5575 24.3236 62.504 23.5608 61.7077 22.5923C60.9114 21.6237 60.5316 20.3766 60.5316 18.8511C60.5316 16.587 61.3891 14.8315 63.092 13.5844C64.7949 12.3374 67.0491 11.7199 69.8668 11.7199C72.5865 11.7199 74.8161 12.3252 76.568 13.536C78.2341 14.6862 79.3367 16.2238 79.9003 18.161C79.9493 18.3305 79.839 18.5121 79.6797 18.5605L75.5879 19.6986C75.4164 19.747 75.2449 19.6381 75.1959 19.4686C74.8774 18.1852 74.2771 17.265 73.4073 16.7081C72.4517 16.1148 71.2756 15.8122 69.8668 15.8122C68.4947 15.8122 67.4043 16.0664 66.6203 16.5628C65.8362 17.0592 65.4442 17.7735 65.4442 18.6695C65.4442 19.6381 65.824 20.3524 66.5958 20.8004C67.3676 21.2483 68.4089 21.5995 69.7197 21.8174L71.4349 22.108C73.1745 22.3985 74.7549 22.8102 76.176 23.3429C77.5971 23.8756 78.7364 24.6263 79.594 25.5707C80.4515 26.5271 80.8681 27.8105 80.8681 29.4329C80.8681 31.8665 79.9615 33.731 78.1361 35.0386C76.3475 36.3462 73.9218 37 70.9081 37Z" />
              <path d="M86.4178 9.1289C85.4377 9.1289 84.6046 8.81411 83.9308 8.18453C83.257 7.55495 82.9263 6.73165 82.9263 5.72674C82.9263 4.72184 83.257 3.91064 83.9308 3.26896C84.6046 2.63938 85.4255 2.32458 86.4178 2.32458C87.3979 2.32458 88.2309 2.63938 88.8925 3.26896C89.554 3.89854 89.897 4.72184 89.897 5.72674C89.897 6.73165 89.5663 7.54284 88.8925 8.18453C88.2187 8.82622 87.3979 9.1289 86.4178 9.1289ZM83.8573 36.0072V12.7006C83.8573 12.5311 84.0043 12.3858 84.1759 12.3858H88.5984C88.77 12.3858 88.917 12.5311 88.917 12.7006V36.0072C88.917 36.1767 88.77 36.322 88.5984 36.322H84.1759C84.0043 36.322 83.8573 36.1888 83.8573 36.0072Z" />
              <path d="M92.5186 4.78238C92.5186 4.61288 92.6656 4.46759 92.8371 4.46759H97.2597C97.4312 4.46759 97.5782 4.61288 97.5782 4.78238V12.071C97.5782 12.2405 97.7252 12.3858 97.8967 12.3858H104.475C104.647 12.3858 104.794 12.5311 104.794 12.7006V16.2965C104.794 16.466 104.647 16.6112 104.475 16.6112H97.8967C97.7252 16.6112 97.5782 16.7565 97.5782 16.926V30.6436C97.5782 31.6122 98.0315 32.0965 98.9503 32.0965H103.691C103.863 32.0965 104.01 32.2418 104.01 32.4113V36.0072C104.01 36.1767 103.863 36.322 103.691 36.322H97.3332C95.863 36.322 94.687 35.8861 93.8171 35.0144C92.9473 34.1427 92.5186 32.9804 92.5186 31.5154V4.78238Z" />
              <path d="M107.416 36.0072V2.65149C107.416 2.48199 107.563 2.3367 107.734 2.3367H112.157C112.328 2.3367 112.475 2.48199 112.475 2.65149V21.7084H113.259L122.693 12.4826C122.754 12.4221 122.827 12.3858 122.913 12.3858H128.72C129.002 12.3858 129.149 12.7369 128.941 12.9306L117.511 23.7667C117.376 23.8877 117.376 24.0936 117.511 24.2146L129.357 35.7771C129.565 35.9709 129.418 36.322 129.137 36.322H123.415C123.33 36.322 123.244 36.2857 123.195 36.2251L113.259 26.3698H112.475V36.0072C112.475 36.1767 112.328 36.322 112.157 36.322H107.734C107.563 36.322 107.416 36.1888 107.416 36.0072Z" />
              <path d="M147.305 14.0808C145.504 12.4947 143.029 11.6956 139.881 11.6956C137.822 11.6956 136.071 12.0225 134.649 12.6642C133.228 13.318 132.077 14.1655 131.207 15.2431C130.411 16.2238 129.81 17.2892 129.418 18.4273C129.357 18.5968 129.455 18.7784 129.627 18.839L133.718 20.1466C133.89 20.2071 134.074 20.0981 134.123 19.9286C134.441 18.7784 135.017 17.8341 135.862 17.0955C136.781 16.2843 138.104 15.8848 139.844 15.8848C141.608 15.8848 142.919 16.3085 143.776 17.1682C144.622 18.0278 145.05 19.1296 145.05 20.4977V21.7326C145.05 21.9021 144.903 22.0474 144.732 22.0474H137.675C135.017 22.0474 132.824 22.677 131.072 23.912C129.32 25.159 128.438 26.9993 128.438 29.4208C128.438 31.0432 128.843 32.4113 129.639 33.5494C130.435 34.6875 131.525 35.535 132.885 36.1283C134.245 36.7094 135.789 37 137.528 37C139.195 37 140.542 36.7699 141.559 36.2978C142.576 35.8256 143.348 35.2807 143.862 34.6512C144.389 34.0216 144.781 33.4404 145.038 32.9319H145.822V36.0193C145.822 36.1888 145.969 36.3341 146.141 36.3341H149.681C149.853 36.3341 150 36.1888 150 36.0193V30.7042V29.7356V20.8488C150 17.9188 149.105 15.6669 147.305 14.0808ZM145.038 26.4182C145.038 28.428 144.401 30.0019 143.152 31.1521C141.89 32.3023 140.26 32.8714 138.263 32.8714C136.793 32.8714 135.629 32.5445 134.772 31.9028C133.914 31.2611 133.498 30.3773 133.498 29.2755C133.498 28.1737 133.902 27.3383 134.723 26.7693C135.544 26.2002 136.622 25.9218 137.969 25.9218H145.05V26.4182H145.038Z" />
              <path d="M0.379778 28.2343H36.4342C36.6424 28.2343 36.8139 28.0648 36.8139 27.859V24.6747C36.8139 24.4689 36.6424 24.2994 36.4342 24.2994H0.379778C0.171513 24.2994 0 24.4689 0 24.6747V27.859C0 28.0648 0.171513 28.2343 0.379778 28.2343Z" />
              <path d="M44.8504 8.09979H8.79604C8.58777 8.09979 8.41626 8.2693 8.41626 8.47512V11.6594C8.41626 11.8652 8.58777 12.0347 8.79604 12.0347H44.8504C45.0587 12.0347 45.2302 11.8652 45.2302 11.6594V8.47512C45.2302 8.2693 45.0587 8.09979 44.8504 8.09979Z" />
              <path d="M44.8505 0H0.379778C0.171513 0 0 0.169503 0 0.375327V2.85733V3.57166V16.5749V17.2893V19.7713C0 19.9771 0.171513 20.1466 0.379778 20.1466H41.016V32.3992H0.379778C0.171513 32.3992 0 32.5687 0 32.7745V35.9588C0 36.1646 0.171513 36.3341 0.379778 36.3341H44.8505C45.0588 36.3341 45.2303 36.1646 45.2303 35.9588V33.4647V32.7624V19.7592V19.0569V16.5628C45.2303 16.357 45.0588 16.1875 44.8505 16.1875H4.21431V3.93488H44.8505C45.0588 3.93488 45.2303 3.76538 45.2303 3.55955V0.375327C45.2303 0.169503 45.0588 0 44.8505 0Z" />
            </svg>
          </Link>
        </Logo>
        {!user && <Nav scrollState={scrollState} changingTransparent={changingTransparent}>
          <Menu>
            <span>Sitka</span>
            <DropDown scrollState={scrollState} changingTransparent={changingTransparent}>
              <Link to="/o-projekte">O projekte</Link>
              <Link to="/galeria">Galéria</Link>
            </DropDown>
          </Menu>
          <Menu>
            <span>Ponuka</span>
            <DropDown scrollState={scrollState} changingTransparent={changingTransparent}>
              <Link to="/ponuka-bytov">Bývanie</Link>
              <Link to="/komercia">Obchodné priestory</Link>
              <Link to="/parkovanie">Parkovanie</Link>
              <Link to="/zoznam-bytov-cennik">Zoznam bytov a cenník</Link>
            </DropDown>
          </Menu>
          {/* <Menu>
            <Link to="/kupa-a-financovanie">Kúpa & Financovanie</Link>
          </Menu> */}
          <Menu>
            <Link to="/developer">Developer</Link>
          </Menu>
          <Menu><ContactButton scrollState={scrollState} changingTransparent={changingTransparent} onClick={() => setOpenDrawer(true)}>Kontakt</ContactButton></Menu>
        </Nav>}
        {user && <Menu><SignOutButton scrollState={scrollState} changingTransparent={changingTransparent} onClick={() => handleSignOut()}>Odhlásiť sa</SignOutButton></Menu>}
        <ContactDrawer open={openDrawer} setOpen={setOpenDrawer} />
        <Hamburger src={scrollState < 0.2 && changingTransparent ? hamburger : darkHamburger} alt="hamburger menu" onClick={() => setOpenMobile(true)} />
        <PhoneHeader openMobile={openMobile} setOpenMobile={setOpenMobile} />
      </Content>
      {!user && <OfferButton onClick={() => history.push("/ponuka-bytov")}>Ponuka bytov</OfferButton>}
    </Container>
  )
}

export default Header
