import React, { useState } from 'react';
import styled from "styled-components";
import { firestore } from "../firebase";

const Input = styled.div`
  flex-basis: 33%;
  margin: 14px 0px;
  display: flex;
  flex-direction: column;
  input, select {
    height: 20px;
  }
`;
const FlatInfo = styled.div`
  width: 80%;
  display: flex;
  background: ${props => props.color};
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  text-align: center;
  > p {
    flex-basis: 33%;
  }
  :hover {
    background: ${props => props.theme.colors.lightCream};
  }
`;
const ButtonSection = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  > button {
    padding: 10px 25px;
    margin: 10px 0px;
  }
`;
const SaveButton = styled.button`
  color: ${props => props.theme.colors.cream};
  background: ${props => props.theme.colors.olive};
  :hover {
    opacity: 0.8;
  }
`;

const EditingRow = ({ flat, setEditingRow }) => {
  const [price, setPrice] = useState(flat.price);
  const [available, setAvailable] = useState(flat.available);

  const handleEdit = (flat) => {
    setEditingRow(null);
    firestore.collection('flats').doc(flat.flatId).set({
      ...flat,
      price,
      available: parseInt(available)
    })
  }

  return (
    <>
      {
        flat &&
        <>
          <FlatInfo>
            <p>{flat.flatId}</p>
            <Input>
              <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
              <small>Číslo vpisujte v tvare bez medzier, čiarok a symbolu €</small>
            </Input>
            <Input>
              <select value={available} onChange={(e) => setAvailable(e.target.value)}>
                <option value="0">Voľný</option>
                <option value="1">Rezervovaný</option>
                <option value="2">Predaný</option>
              </select>
              <small>Vyberte zo zoznamu dostupnosť bytu</small>
            </Input>
          </FlatInfo>
          <ButtonSection>
            <SaveButton onClick={() => handleEdit(flat)}>
              Uložiť zmenu
            </SaveButton>
          </ButtonSection>
        </>
      }
    </>
  )
}

export default EditingRow
