import React, { useState } from 'react';
import styled from "styled-components";
import FlatsManagement from "../components/FlatsManagement";
import ParkingManagement from "../components/ParkingManagement";
import Header from "../components/Header";
import Login from "../components/Login";
import { auth } from "../firebase";

const Container = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 30px;
`;

const Tabs = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const TabButton = styled.button`
  border: none;
  background: transparent;
  border-bottom: ${props => props.activeTab ? "2px solid #2B4039" : "none"};
  color: ${props => props.activeTab? props.theme.colors.green : props.theme.colors.grey};
`;

const Admin = () => {
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState(0)

  const handleSignOut = () => {
    auth.signOut()
      .then(() => setUser(null));
  }

  return (
    <>
      <Header user={user} handleSignOut={handleSignOut} />
      <Container className={"container"}>
        {user ? 
          <>
            <Heading>
              <h1>Vitaj, Admin</h1>
            </Heading>
            <Tabs>
              <TabButton activeTab={activeTab === 0} onClick={() => setActiveTab(0)}>Úprava bytov</TabButton>
              <TabButton activeTab={activeTab === 1} onClick={() => setActiveTab(1)}>Parkovanie</TabButton>
            </Tabs>
            {activeTab === 0 && <FlatsManagement />}
            {activeTab === 1 && <ParkingManagement />}
          </>
          :
          <Login setUser={setUser} />
        }
      </Container>
    </>
  )
}

export default Admin
