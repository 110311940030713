import React, { useState } from 'react';
import styled from "styled-components";
import { Link } from "react-router-dom";
import { firestore } from "../firebase";
import emailSvg from "../assets/images/email.svg";
import call from "../assets/images/call.svg";
import arrow from "../assets/images/arrow.svg";
import Loading from "./Loading";

const Container = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1200;
  overflow: hidden;
  padding-top: 25px;
  @media only screen and (max-height: 600px) {
    padding-top: 0px;
  }
  height: 100%;
  width: ${props => props.open ? "33vw" : "0"};
  max-width: 600px;
  @media only screen and (max-width: 1200px) {
    width: ${props => props.open ? "40vw" : "0"};
  }
  @media only screen and (max-width: 900px) {
    width: ${props => props.open ? "100%" : "0"};
    max-width: 100%;
  }
  background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.cream};
  transition: 0.5s;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  @media only screen and (max-width: 1200px) {
    margin-left: 30px;
  }
  
  @media only screen and (max-width: 900px) {
    align-items: center;
    margin-left: 0px;
  }
  @media only screen and (max-width: 600px) {
    max-width: 100%;
    padding: 0px 30px;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 520px;
    margin-left: 50px;
  }
`;

const Button = styled.button`
  background: transparent;
  border: none;
  color: ${props => props.theme.colors.cream};
  cursor: pointer;
  margin-left: auto;
  margin-right: 20px;
  @media only screen and (max-width: 900px) {
    align-self: flex-end;
    margin-right: 0;
  }
  width: 25%;
  font-family: "Space Grotesk";
  font-feature-settings: 'ss04' on;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 115%;

  :hover {
    opacity: 0.7;
  }
`;

const Heading = styled.h2`
  font-family: "Space Grotesk";
  font-feature-settings: 'ss04' on;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 115%;
  text-transform: uppercase;
  margin-bottom: 40px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  color: ${props => props.theme.colors.lightCream};
  @media only screen and (max-width: 600px) {
    max-height: 400px;
  }
  @media only screen and (max-height: 600px) {
    max-height: 370px;
  }
`;

const Input = styled.input`
  padding: 15px;
  background: #333333;
  border: none;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: ${props => props.theme.colors.lightCream};
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 12px;
  }
  @media only screen and (max-height: 600px) {
    margin-bottom: 10px;
  }
  :focus {
    outline: none;
  };
  ${props => props.error? `margin-bottom: 0px; outline: 1px solid #EEEDDE` : ""};
`;

const Textarea = styled.textarea`
  padding: 15px;
  background: #333333;
  border: none;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: ${props => props.theme.colors.lightCream};
  resize: none;
  height: 10em;
  margin-bottom: 20px;
  :focus {
    outline: none;
  }
`;

const Submit = styled.button`
  background: ${props => props.theme.colors.lightCream};
  color: ${props => props.theme.colors.darkGreen};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  > img {
    transform: rotate(-90deg);
  }
`;

const Small = styled.small`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  color: ${props => props.theme.colors.cream};
  opacity: 0.6;
  margin-bottom: 20px;
`;

const ContactInfo = styled.div`
  display: flex; 
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
`;

const Paragraph = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-family: "Work Sans";

  > img {
    margin-right: 10px;
  }
`;

const ErrorMessage = styled.p`
  margin: 0;
`;

function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const ContactDrawer = ({ open, setOpen, flatId }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("")
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateEmail(email)) {
      firestore.collection('messages').add({
      senderName: name,
      senderEmail: email,
      phone: number,
      message: message,
      subject: `Kontaktny formular SITKA`,
      flatId: (flatId ? flatId : "Bez id bytu")
    })
      .then(() => {
        setSuccess("Sprava bola odoslana");
        setLoading(false);
        setTimeout(() => setSuccess(""), 5000);
        setTimeout(() => setOpen(false), 2000);
        setName("");
        setEmail("");
        setNumber("");
        setMessage("");
      })
      .catch(() => {
        setLoading(false);
        setError({
          type: "general",
          message: "Nepodarilo sa odoslat spravu"
        })
        setTimeout(() => setError(null), 5000);
      })
    } else {
      setLoading(false);
      setError({
        type: "email",
        message: "Neplatna emailova adresa"
      });
      setTimeout(() => setError(null), 5000);
    }
  }

  return (
    <Container open={open}>
      <Content>
        <Button onClick={() => setOpen(false)}>Zavrieť</Button>
        <Heading>Kontaktujte nás</Heading>
        {(error && error.type === "general") && <ErrorMessage>{error.message}</ErrorMessage>}
        { success && <ErrorMessage>{success}</ErrorMessage>}
        <Form onSubmit={(e) => handleSubmit(e)} autocomplete="off">
          <Input type="text" name="name" placeholder="Meno a priezvisko" value={name} onChange={(e) => setName(e.target.value)} aria-label="Meno"/>
          <Input type="email" name="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} error={error} aria-label="Email"/>
          { (error && error.type === "email") && <ErrorMessage>{error.message}</ErrorMessage>}
          <Input type="tel" name="phone" placeholder="Telefón" value={number} onChange={(e) => setNumber(e.target.value)} aria-label="Telefon"/>
          <Textarea placeholder="Vaša správa pre nás" value={message} onChange={(e) => setMessage(e.target.value)} aria-label="Sprava"/>
          <Small>Odoslaním formulára súhlasíte so <Link to="/gdpr" style={{ textDecoration: "underline" }}>spracovaním osobných údajov</Link>, a kontaktovaním Vašej osoby.</Small>
          {loading ?
            <Loading message={"Odosielam správu"} />
            : 
            <Submit type="submit">
              Odoslať
              <img src={arrow} alt="arrow" />
            </Submit>
          }
        </Form>
        <ContactInfo>
          <Paragraph>
            <img src={call} alt="telephone" />
            0907 726 660
          </Paragraph>
          <Paragraph>
            <img src={emailSvg} alt="email" />
            sitka@sitkatn.sk
          </Paragraph>
        </ContactInfo>
      </Content>
    </Container>
  )
}

export default ContactDrawer
