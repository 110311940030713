import React from 'react';
import styled from "styled-components";
import loadingSvg from "../assets/images/loading.svg";

const Wrapper = styled.div`
  text-align: center;
  > p {
    margin: 0px;
  }
`;

const Svg = styled.img`
  width: 50px;
`;

const Loading = ({ message }) => {
  return (
    <Wrapper>
      <Svg src={loadingSvg} alt="loading" />
      <p>{message}</p>
    </Wrapper>
  )
}

export default Loading
