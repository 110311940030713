import React, { useState } from 'react';
import cancel from "../assets/images/x.svg";
import logoMobile from "../assets/images/logo-small.svg";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import ContactDrawer from "./ContactDrawer";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100vh;
  width: ${props => props.open ? "100%" : "0vw"};
  animation: ${fadeIn} 0.7s;
  background: ${props => props.theme.colors.darkGreen};
  display: none;
  @media only screen and (max-width: 900px) {
    display: ${props => props.open ? "block" : "none"};
  }
`;

const Content = styled.div`
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
  @media only screen and (max-width: 900px) {
    max-width: 720px;
  };
  @media only screen and (min-width: 1200px) {
    max-width: 1140px;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Nav = styled.div`
  height: 100px;
  @media only screen and (max-width: 600px) {
    height: 80px;
  }
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > img {
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
  }
`;

const Links = styled.div`
  margin-top:auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.colors.cream};
  font-family: "Space Grotesk";
  font-feature-settings: 'ss04' on;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  @media only screen and (max-height: 600px) {
    font-size: 18px;
    line-height: 24px;
  }

  > a {
    margin-bottom: 15px;
  }
`;

const ContactWrapper = styled.div`
  margin-bottom: 85px;
  text-align: center;
  margin-top: auto;
`;

const ContactButton = styled.button`
  background: ${props => props.theme.colors.cream};
  color: ${props => props.theme.colors.darkGreen};
  font-family: "Space Grotesk";
  font-feature-settings: 'ss04' on;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 115%;
  padding: 15px 100px;
  cursor: pointer;
  width: 100%;
  max-width: 320px;
  @media only screen and (max-width: 600px) {
    max-width: 100%;
  };
`;

const PhoneHeader = ({ openMobile, setOpenMobile }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleClose = () => {
    setOpenMobile(false);
  };

  return (
    <Container open={openMobile}>
      <Content>
        <Nav>
          <Link to="/">
            <img src={logoMobile} alt="logo" />
          </Link>
          <img src={cancel} alt="cancel button" onClick={() => handleClose()} />
        </Nav>
        <Links>
          <Link to="/o-projekte">O projekte</Link>
          <Link to="/galeria">Galéria</Link>
          <Link to="/ponuka-bytov">Bývanie</Link>
          <Link to="/komercia">Obchodné priestory</Link>
          <Link to="/parkovanie">Parkovanie</Link>
          <Link to="/zoznam-bytov-cennik">Zoznam bytov a cenník</Link>
          {/* <Link to="/kupa-a-financovanie">Kúpa & Financovanie</Link> */}
          <Link to="/developer">Developer</Link>
        </Links>
        <ContactWrapper>
          <ContactButton onClick={() => setOpenDrawer(true)}>Kontakt</ContactButton>
        </ContactWrapper>
        <ContactDrawer open={openDrawer} setOpen={setOpenDrawer} />
      </Content>
    </Container>
  )
}

export default PhoneHeader
