import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import useStorage from "../utils/useStorage";
import { firestore } from "../firebase";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    text-align: center;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #CDCBAC;
  width: 350px;
`;

const Label = styled.label`
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid ${props => props.theme.colors.green};
  margin: 10px auto;
  line-height: 30px;
  color: ${props => props.theme.colors.green};
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
  :hover {
    background: ${props => props.theme.colors.green};
    color: ${props => props.theme.colors.cream};
  }
  input {
    height: 0;
    width: 0;
    opacity: 0;
  }
`;

const ParkingManagement = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const { url, progress } = useStorage(file);
  const [doc, setDoc] = useState(null)

  const handleChange = (e) => {
    let selected = e.target.files[0]

    if (selected && selected.type === "application/pdf") {
      setFile(selected)
      setError("")
    } else {
      setFile(null)
      setError("Vyberte PDF")
    }
  }

  useEffect(() => {
    const docRef = firestore.collection('parkings').doc('park')
    docRef.get().then((doc) => {
    if (doc.exists) {
      setDoc(doc.data())
    }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }, [url])

  return (
    <Wrapper>
      <div>
        <h1>Aktuálny súbor:</h1>
        {doc && <p>Názov súboru: <b>{doc.parkingName}</b></p>}
        {doc && <p><a href={doc.parkingUrl} target={"about_blank"} rel="noopener noreferrer">Zobraziť</a></p>}
      </div>
      <Divider />
      <div>
        <h1>Nahrať nový súbor:</h1>
        <form>
          <Label>
            <input type="file" onChange={handleChange} />
            <span>+</span>
          </Label>
          {error && <p>{error}</p>}
          {file && <p>Nahrávam súbor: <b>{file.name}</b></p>}
          {progress && <b>{progress.toFixed(1)}%</b>}
          {progress === 100 && <p><b>Súbor bol úspešne nahratý</b></p>}
        </form>
      </div>
    </Wrapper>
  )
}

export default ParkingManagement
