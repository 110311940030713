import { useState, useEffect } from "react";
import { storage, firestore } from "../firebase";

const useStorage = (file) => {
  const [progress, setProgress] = useState();
  const [error, setError] = useState();
  const [url, setUrl] = useState();

  useEffect(() => {
    if (file) {
      const storageRef = storage.ref().child(`parkings/` + file.name)
      storageRef.put(file).on('state_changed', (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(percentage);
      }, (err) => {
        setError(err);
      }, async () => {
          const url = await storageRef.getDownloadURL();
          setUrl(url);
          firestore.collection('parkings').doc('park').set({
            parkingName: file.name,
            parkingUrl: url
          }).catch(err => console.log(err))
      })
    }
  }, [file])

  return { progress, url, error }
};

export default useStorage;