import React from 'react'
import styled from "styled-components";
import { motion } from "framer-motion";

const Container = styled.section`
  z-index: 999;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;

  > div {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const variants = {
  visible: {
    opacity: 1,
    transition: {
      yoyo: Infinity,
      ease: "linear",
      duration: 2
    }
  },
  hidden: {
    opacity: 0.1,  
  },
}



const PageLoading = () => {
  return (
    <Container>
      <div>
        <motion.svg width="160" height="129" viewBox="0 0 160 129" fill="none" xmlns="http://www.w3.org/2000/svg"
        initial="hidden"
        animate="visible"
        variants={variants}
        >
          <path d="M1.54866 100.339H128.871C129.606 100.339 130.212 99.7391 130.212 99.0108V87.7436C130.212 87.0153 129.606 86.4155 128.871 86.4155H1.54866C0.813196 86.4155 0.20752 87.0153 0.20752 87.7436V99.0108C0.20752 99.7391 0.813196 100.339 1.54866 100.339Z" fill="#ADA886"/>
          <path d="M158.592 29.0947H31.2703C30.5349 29.0947 29.9292 29.6945 29.9292 30.4228V41.69C29.9292 42.4183 30.5349 43.0181 31.2703 43.0181H158.592C159.328 43.0181 159.933 42.4183 159.933 41.69V30.4228C159.933 29.6945 159.328 29.0947 158.592 29.0947Z" fill="#ADA886"/>
          <path d="M158.592 0.434082H1.54866C0.813196 0.434082 0.20752 1.03386 0.20752 1.76215V10.5446V13.0722V59.0834V61.611V70.3934C0.20752 71.1217 0.813196 71.7215 1.54866 71.7215H145.051V115.077H1.54866C0.813196 115.077 0.20752 115.676 0.20752 116.405V127.672C0.20752 128.4 0.813196 129 1.54866 129H158.592C159.327 129 159.933 128.4 159.933 127.672V118.847V116.362V70.3506V67.8658V59.0405C159.933 58.3122 159.327 57.7125 158.592 57.7125H15.0899V14.3574H158.592C159.327 14.3574 159.933 13.7576 159.933 13.0293V1.76215C159.933 1.03386 159.327 0.434082 158.592 0.434082Z" fill="#ADA886"/>
        </motion.svg>
        <p>Načítavam...</p>
      </div>
    </Container>
  )
}

export default PageLoading
