import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    black: "#1B1C1E",
    darkGreen: "#15221E",
    olive: "#25302C",
    green: "#2B4039",
    darkGrey: "#4A4A45",
    brown: "#BC9B6A",
    cream: "#EEEDDE",
    grey: "#C4C4C4",
    white: "#FFFFFF",
    lightCream: "#F7F6EF",
  },
  fonts: ["Work Sans", "Space Grotesk", "sans serif"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em"
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;