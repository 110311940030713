import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./assets/fonts/SpaceGrotesk-Bold.woff2";
import "./assets/fonts/SpaceGrotesk-Bold.ttf";
import "./assets/fonts/SpaceGrotesk-Bold.otf";
import "./assets/fonts/SpaceGrotesk-Light.woff2";
import "./assets/fonts/SpaceGrotesk-Light.ttf";
import "./assets/fonts/SpaceGrotesk-Light.otf";
import "./assets/fonts/SpaceGrotesk-Medium.woff2";
import "./assets/fonts/SpaceGrotesk-Medium.ttf";
import "./assets/fonts/SpaceGrotesk-Medium.otf";
import "./assets/fonts/SpaceGrotesk-Regular.woff2";
import "./assets/fonts/SpaceGrotesk-Regular.ttf";
import "./assets/fonts/SpaceGrotesk-Regular.otf";
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
