import React, { useState } from 'react'
import styled from "styled-components";
import useFetchFlats from "../utils/useFetchFlats"
import EditingRow from "./EditingRow";

const Wrapper = styled.div`
  width: 800px;
  @media only screen and (max-width: 800px) {width: 100%};
`;

const TableHead = styled.div`
  width: 80%;
  display: flex;
  color: ${props => props.theme.colors.grey};
  text-align: center;
  > p {
    flex-basis: 33%;
  }
`;

const Table = styled.div`
  margin-bottom: 80px;
`;
const TableRow = styled.div`
  display: flex;
  color: ${props => props.theme.colors.darkGreen};
  align-items: center;
  line-height: 36px;
`;
const FlatInfo = styled.div`
  width: 80%;
  display: flex;
  background: ${props => props.color};
  border-bottom: 1px solid ${props => props.theme.colors.grey};
  text-align: center;
  > p {
    flex-basis: 33%;
  }
  :hover {
    background: ${props => props.theme.colors.lightCream};
  }
`;
const ButtonSection = styled.div`
  width: 20%;
  > button {
    padding: 10px 25px;
  }
`;
const EditButton = styled.button`
  color: ${props => props.theme.colors.darkGreen};
  border: 1px solid ${props => props.theme.colors.olive};
  background: transparent;
  :hover {
    color: ${props => props.theme.colors.cream};
    background: ${props => props.theme.colors.olive};
  }
`;


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const toStatusString = (status) => {
  if (status === 0) return "Voľný";
  if (status === 1) return "Rezervovaný";
  if (status === 2) return "Predaný";
};

const getColor = (status) => {
  /* if (status === 0) return "#fff";
  if (status === 1) return "rgba(242,153,74,0.5)";
  if (status === 2) return "rgba(235,87,87,0.5)"; */
  if (status === 0) return "rgba(242,153,74,0.5)";
  if (status === 1) return "rgba(235,87,87,0.5)";
  if (status === 2) return "#fff";
}

const FlatsManagement = () => {
  const [editingRow, setEditingRow] = useState(null);
  const { flats, loading, error } = useFetchFlats();

  return (
    <Wrapper>
      <TableHead>
        <p>ID Bytu</p>
        <p>Cena s DPH</p>
        <p>Dostupnosť</p>
      </TableHead>
      <Table>
        {loading && <p>Načítavam byty</p>}
        {error && <p>Chyba. Refreshnite web</p>}
        {
          flats && flats.map(flat => (
            <TableRow key={flat.flatId}>
              {
                editingRow === flat.flatId? 
                  <EditingRow flat={flat} setEditingRow={setEditingRow} />
                  :
                  <>
                    <FlatInfo color={getColor(flat.available)}>
                      <p>{flat.flatId}</p>
                      <p>{numberWithCommas(flat.price)}€</p>
                      <p>{toStatusString(flat.available)}</p>
                    </FlatInfo>
                    <ButtonSection>
                      <EditButton onClick={() => setEditingRow(flat.flatId)}>
                        Upraviť hodnoty
                      </EditButton>
                    </ButtonSection>
                  </>
              }
            </TableRow>
          ))
        }
      </Table>
    </Wrapper>
  )
}

export default FlatsManagement
