import React, { useState } from 'react';
import styled from "styled-components";
import { auth } from "../firebase";

const Wrapper = styled.div`
  width: 50%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  text-align: center;
  margin-top: 50px;
`;

const Heading = styled.h2`
  font-family: "Space Grotesk";
  font-feature-settings: 'ss04' on;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 115%;
  text-transform: uppercase;
  margin-bottom: 40px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 75%;
  padding: 15px;
  background: ${props => props.theme.colors.lightCream};
  border: none;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: ${props => props.theme.colors.olive};
  margin-bottom: 20px;
  @media only screen and (max-width: 600px) {
    margin-bottom: 12px;
  }
  @media only screen and (max-height: 600px) {
    margin-bottom: 10px;
  }
  :focus {
    outline: none;
  };
`;

const Submit = styled.input`
  cursor: pointer;
  padding: 20px 30px;
  margin-top: 20px;
  max-width: 340px;
  width: 240px;
  @media only screen and (max-width: 900px) {
    max-width: unset;
    width: 100%;
  }
  border: 1px solid ${props => props.theme.colors.darkGreen};
  color: ${props => props.theme.colors.lightCream};
  background: ${props => props.theme.colors.olive};
  :hover {
    color: ${props => props.theme.colors.darkGreen};
    background: transparent;
  }
`;

const errors = {
  "auth/user-not-found": "Žiadny užívateľ s týmto emailom nebol nájdený",
  "auth/wrong-password": "Nesprávne heslo"
}

const Login = ({ setUser }) => {
  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    auth.signInWithEmailAndPassword(email, password)
      .then((user) => {
        setEmail("");
        setPassword("");
        setUser(user)
      })
      .catch(err => {
        setPassword("");
        const errorMessage = errors[err.code] ? errors[err.code] : err.message; 
        setError(errorMessage);
        setTimeout(() => setError(""), 10000);
      })
  }

  return (
    <Wrapper>
      <Heading>Prihlásenie</Heading>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Input type="email" name="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
        <Input type="password" name="password" placeholder="Heslo" value={password} onChange={e => setPassword(e.target.value)} />
        {error && <p>{error}</p>}
        <Submit type="submit" value="Prihlásiť sa" />
      </Form>
    </Wrapper>
  )
}

export default Login
