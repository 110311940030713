import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";
import Theme from "./Theme";
import PageLoading from "./components/PageLoading";
import Admin from "./views/Admin";

const FlatsOffer = React.lazy(() => import("./views/FlatsOffer"));
const FlatsList = React.lazy(() => import("./views/FlatsList"));
const Footer = React.lazy(() => import("./components/Footer"));
const GalleryView = React.lazy(() => import("./views/GalleryView"));
const AboutProject = React.lazy(() => import("./views/AboutProject"));
const Commerce = React.lazy(() => import("./views/Commerce"));
const PageNotFound = React.lazy(() => import("./views/PageNotFound"));
const Developer = React.lazy(() => import("./views/Developer"));
const Gdpr = React.lazy(() => import("./views/Gdpr"));
const Parking = React.lazy(() => import("./views/Parking"));
const HomePage = React.lazy(() => import("./views/HomePage"))
/* const PurchaseAndFinancing = React.lazy(() => import("./views/PurchaseAndFinancing")); */

function App() {

  return (
    <Router>
      <Theme>
        <Suspense fallback={<PageLoading />}>
          <Switch>
            <Route exact path="/">
              <ScrollToTop />
              <HomePage />
            </Route>
            {/* <Route path="/kupa-a-financovanie">
              <ScrollToTop />
              <PurchaseAndFinancing />
            </Route> */}
            <Route path="/ponuka-bytov">
              <ScrollToTop />
              <FlatsOffer />
            </Route>
            <Route path="/zoznam-bytov-cennik">
              <ScrollToTop />
              <FlatsList />
            </Route>
            <Route path="/galeria">
              <ScrollToTop />
              <GalleryView />
            </Route>
            <Route path="/o-projekte">
              <ScrollToTop />
              <AboutProject />
            </Route>
            <Route path="/komercia">
              <ScrollToTop />
              <Commerce />
            </Route>
            <Route path="/developer">
              <ScrollToTop />
              <Developer />
            </Route>
            <Route path="/gdpr">
              <ScrollToTop />
              <Gdpr />
            </Route>
            <Route path="/parkovanie">
              <ScrollToTop />
              <Parking />
            </Route>
            <Route path="/admin">
              <ScrollToTop />
              <Admin />
            </Route>
            <Route>
              <PageNotFound />
            </Route>
          </Switch>
          <Footer />
        </Suspense>
      </Theme>
    </Router>
  );
}

export default App;
